@import '../../styles/variables';
@import '../../styles/functions';
@import '../../styles/responsive';

@font-face {
    font-family: 'hatton_bold';
    src: url('../../assets/fonts/Hatton-Bold.woff2');
}

@font-face {
    font-family: 'TheinhardtPan_medium';
    src: url('../../assets/fonts/TheinhardtPan-Medium.otf');
    font-weight: 500;
}

.myprogress_outer {
    top: 50% !important;
    left: 50% !important;
    width: 560px;
    height: 997px;
    min-height: 560px;
    max-height: 997px;

    .wrapper_myprogress {
        transform: translate(-50%, -50%);
        width: 560px;
        height: 85%;
        min-height: 560px;
        background-color: $white;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        border-radius: 10px;
        outline: none;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 8px !important;
            background-color: #f4f4f4;
            cursor: pointer;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background-color: $grey900;
        }
        .cross {
            position: absolute;
            top: rem(30);
            right: rem(30);
            cursor: pointer;
        }
        .modal_heading {
            padding: 20px 24px;
        }
        .chart_progress {
            padding: 0 24px;
            display: flex;
            width: 100%;
            justify-content: space-around;
            align-items: center;
            border-top: 1px solid $grey800;
            border-bottom: 1px solid $grey800;
            .influencer_progress {
                width: 100%;
                padding: rem(28) 0;
                border-right: 1px solid $grey800;
                .chart_heading {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: rem(20);
                    & > div:nth-child(2) {
                        display: flex;
                        align-items: center;
                        svg {
                            width: 15px;
                            height: 15px;
                            margin-left: 8px;
                            fill: #c7c7c7;
                        }
                    }
                }
            }
            & > .influencer_progress:last-child {
                border: none;
            }
            .circular-chart {
                width: 106px;
                height: 106px;
                display: block;
                margin: 10px auto;
                max-width: 80%;
                max-height: 250px;
            }
            .circle-bg {
                fill: none;
                stroke: #f2f2f2;
                stroke-width: 3.8;
            }
            .circle {
                fill: none;
                stroke-width: 2.8;
                stroke-linecap: round;
                animation: progress 1s ease-out forwards;
            }
            @keyframes progress {
                0% {
                    stroke-dasharray: 0 100;
                }
            }
            .circular-chart.orange .circle {
                stroke: #d63f1c;
            }
            .percentage {
                fill: $black100;
                font-family: 'TheinhardtPan';
                font-size: 0.4rem;
                text-anchor: middle;
            }
            .percentage_bold {
                fill: $black100;
                font-family: 'TheinhardtPan_medium';
                font-size: 0.4rem;
                text-anchor: middle;
            }
        }
        .badges_container {
            width: 100%;
            height: 127px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: rem(28);
            padding-bottom: rem(28);
            background-color: $lightGrey2;
            .heading {
                display: flex;
                justify-content: center;
                width: 100%;
                margin-bottom: rem(21);
            }
            .badge_sequence {
                display: flex;
                align-items: flex-start;
                .badge_element {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    img {
                        width: rem(35);
                        height: rem(35);
                    }
                }
                .badge_element.faded {
                    opacity: 0.3;
                }
                .badge_link {
                    width: rem(105);
                    height: 0.5px;
                    background-color: $grey1100;
                    margin-top: 12px;
                }
            }
        }
        .contentstates_wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            &::-webkit-scrollbar {
                width: 10px !important;
                background-color: #f4f4f4;
                cursor: pointer;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 4px;
                background-color: $grey900;
            }
            .content_stats {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                border-bottom: 1px solid $grey900;
                padding: 0 48px;
                .heading {
                    margin-bottom: rem(10);
                    margin-top: rem(24);
                }
                .row {
                    cursor: pointer;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    padding: rem(14) 0;
                    border-bottom: 1px solid $grey900;
                    & > span:first-child {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        & > div:nth-child(2) {
                            cursor: pointer;
                            margin-left: rem(10);
                        }
                    }
                    & > div:nth-child(2) {
                        display: flex;
                        align-items: center;
                        .arrow_action {
                            width: rem(12);
                            height: rem(12);
                            background-image: url('./MyProgressComponent/assets/triangle.svg');
                            background-repeat: no-repeat;
                            background-size: contain;
                            margin-left: rem(8);
                        }
                    }
                    .fraction {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
                & > .row:last-child {
                    border: none;
                }
            }
            & > .content_stats:last-child {
                border: none;
                margin-bottom: rem(34);
            }
        }
        .streaks_wrapper {
            width: 100%;
            height: 159px;
            background-color: $grey50;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            border-bottom: 1px solid $grey900;
            padding: 0 48px;
            .streakTitle {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-top: 10px;
                margin-bottom: 10px;
                & > div:nth-child(2) {
                    display: flex;
                    align-items: center;
                    svg {
                        width: 15px;
                        height: 15px;
                        margin-left: 8px;
                        fill: $grey900;
                    }
                }
            }
            .row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-top: auto;
            }
            .streakData {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
            }
            .daysContaine {
                width: 100%;
                margin: 30px;
                display: flex;
                align-self: center;
            }
            .circle {
                height: 30px;
                width: 30px;
                background-color: $borderOrange;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 0.1px solid $red100;
            }
            .circleDisable {
                height: 30px;
                width: 30px;
                background-color: #c7c7c7;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .daysRow {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                width: 100%;
            }
            .circleContaine {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }
            .column {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: column;
            }
            .activeDay {
                color: $grey1000;
            }
            .inactiveDay {
                color: $grey100;
            }
        }
        .chapter_details_wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            .heading_wrapper {
                width: 100%;
                height: 10%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                border-bottom: 1px solid $grey900;
                & > div:first-child {
                    width: 10px;
                    height: 17px;
                    margin-left: 20px;
                    margin-right: 8px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    background-image: url('./MyProgressComponent/assets/chapterback.svg');
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }
            .chapter_wrapper {
                width: 100%;
                height: 90%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                overflow-y: scroll;
                &::-webkit-scrollbar {
                    width: 10px !important;
                    background-color: #f5f5f5;
                    cursor: pointer;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 4px;
                    background-color: $grey900;
                }
                .chapter {
                    width: 100%;
                    padding: 24px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    border-bottom: 1px solid $grey900;
                    & > div:nth-child(2) {
                        padding: 0 24px;
                    }
                    .chapter_index {
                        display: flex;
                        justify-content: space-between;
                        padding: 0 24px;
                        margin-bottom: rem(10);
                    }
                    .pre_chapter {
                        display: flex;
                        justify-content: space-between;
                        padding: 0 24px;
                        margin: 15px 0;
                        .score {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            & > div:first-child {
                                margin-right: rem(8);
                            }
                        }
                    }
                }
                & > .chapter:last-child {
                    border: none;
                }
            }
        }
    }
}

@media (max-width: 1366px) {
    .myprogress_outer {
        .wrapper_myprogress {
            height: 70%;
        }
    }
}

@media (max-width: $fullHdContentWidth) {
    .myprogress_outer {
        .wrapper_myprogress {
            height: 70%;
        }
    }
}

@media (max-width: $breakpointMobileLandScape) {
    .myprogress_outer {
        width: 100%;
        height: 100%;
        .wrapper_myprogress {
            width: 100%;
            height: 100%;
            .badges_container {
                padding: 24px 40px;
            }
        }
    }
}
