@import '../../../styles/variables';
@import '../../../styles/responsive';
@import '../../../styles/functions';
@import '../../../styles/default-classes';

@font-face {
    font-family: 'TheinhardtPan';
    src: url('../../../assets/fonts/TheinhardtPan-Regular.otf');
}

.unsubscribe {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .question {
        font-size: rem(18);
        font-family: 'TheinhardtPan';
    }
    button {
        cursor: pointer;
        outline: none;
        width: rem(170);
        height: rem(45);
        font-size: rem(18);
        font-family: 'TheinhardtPan';
        color: $white;
        background-color: $black100;
        border-radius: rem(22);
        margin-top: rem(20);
    }
    img {
        margin-bottom: rem(30);
        width: 151px;
        height: 112px;
        object-fit: contain;
    }
    .success {
        font-size: rem(25);
        font-weight: 600;
        font-family: 'TheinhardtPan';
    }
    .msg {
        margin-top: rem(10);
        font-size: rem(18);
        font-family: 'TheinhardtPan';
    }
}
