@import '../../styles/variables';

.feed_card_wrapper {
    width: 100%;
    background-color: $white;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    margin-bottom: 1.25rem;
    .user_details_section {
        padding: 1.25rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
        .display_picture_name_wrapper {
            display: flex;
            & > div:first-child {
                margin-right: 0.625rem;
            }
            & > div:nth-child(2) {
                display: flex;
                flex-direction: column;
            }
        }
    }
    .content_wrapper {
        width: 100%;
        video {
            width: 100%;
        }
    }
    .title_wrapper {
        width: 100%;
        padding: 1.25rem;
        font-size: 1rem;
        line-height: 1.35;
        letter-spacing: 0.25px;
        white-space: pre-wrap;
        ._heading {
            color: #2b2b2b;
            word-break: break-word;
            padding-right: 3px;
            a {
                text-decoration: underline;
                color: $blue100;
                cursor: pointer;
            }
        }
        .tagHeading {
            color: $blueBtns;
            height: 30px;
            background-color: $bgFilterGrey;
            padding: 6px 15px;
            margin-top: 5px;
            display: inline-block;
            border-radius: 30px;
            margin-right: 5px;
        }
    }
    .like_comment_wrapper {
        width: 100%;
        display: flex;
        margin-top: 1.75rem;
        padding: 1rem 0;
        border-top: 1px solid #d8d8d8;
        border-bottom: 1px solid #d8d8d8;
        #main-content {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50%;
            .like-icon {
                cursor: pointer;
                padding-right: 5px;
            }
            h1 {
                display: none;
            }
            a {
                display: none;
            }

            svg {
                cursor: pointer;
                overflow: visible;
                width: 2rem;
                #heart {
                    fill: #cccccc;
                    transform-origin: center;
                    animation: animateHeartOut 0.3s linear forwards;
                }
                #main-circ {
                    transform-origin: 29.5px 29.5px;
                }
            }

            input {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 0;
            }

            input:checked + label svg {
                #heart {
                    transform: scale(0.2);
                    fill: #1f40e6;
                    animation: animateHeart 0.3s linear forwards 0.25s;
                }
                #main-circ {
                    transition: all 2s;
                    animation: animateCircle 0.3s linear forwards;
                    opacity: 1;
                }
                #grp1 {
                    opacity: 1;
                    transition: 0.1s all 0.3s;
                    #oval1 {
                        transform: scale(0) translate(0, -30px);
                        transform-origin: 0 0 0;
                        transition: 0.5s transform 0.3s;
                    }
                    #oval2 {
                        transform: scale(0) translate(10px, -50px);
                        transform-origin: 0 0 0;
                        transition: 1.5s transform 0.3s;
                    }
                }
                #grp2 {
                    opacity: 1;
                    transition: 0.1s all 0.3s;
                    #oval1 {
                        transform: scale(0) translate(30px, -15px);
                        transform-origin: 0 0 0;
                        transition: 0.5s transform 0.3s;
                    }
                    #oval2 {
                        transform: scale(0) translate(60px, -15px);
                        transform-origin: 0 0 0;
                        transition: 1.5s transform 0.3s;
                    }
                }
                #grp3 {
                    opacity: 1;
                    transition: 0.1s all 0.3s;
                    #oval1 {
                        transform: scale(0) translate(30px, 0px);
                        transform-origin: 0 0 0;
                        transition: 0.5s transform 0.3s;
                    }
                    #oval2 {
                        transform: scale(0) translate(60px, 10px);
                        transform-origin: 0 0 0;
                        transition: 1.5s transform 0.3s;
                    }
                }
                #grp4 {
                    opacity: 1;
                    transition: 0.1s all 0.3s;
                    #oval1 {
                        transform: scale(0) translate(30px, 15px);
                        transform-origin: 0 0 0;
                        transition: 0.5s transform 0.3s;
                    }
                    #oval2 {
                        transform: scale(0) translate(40px, 50px);
                        transform-origin: 0 0 0;
                        transition: 1.5s transform 0.3s;
                    }
                }
                #grp5 {
                    opacity: 1;
                    transition: 0.1s all 0.3s;
                    #oval1 {
                        transform: scale(0) translate(-10px, 20px);
                        transform-origin: 0 0 0;
                        transition: 0.5s transform 0.3s;
                    }
                    #oval2 {
                        transform: scale(0) translate(-60px, 30px);
                        transform-origin: 0 0 0;
                        transition: 1.5s transform 0.3s;
                    }
                }
                #grp6 {
                    opacity: 1;
                    transition: 0.1s all 0.3s;
                    #oval1 {
                        transform: scale(0) translate(-30px, 0px);
                        transform-origin: 0 0 0;
                        transition: 0.5s transform 0.3s;
                    }
                    #oval2 {
                        transform: scale(0) translate(-60px, -5px);
                        transform-origin: 0 0 0;
                        transition: 1.5s transform 0.3s;
                    }
                }
                #grp7 {
                    opacity: 1;
                    transition: 0.1s all 0.3s;
                    #oval1 {
                        transform: scale(0) translate(-30px, -15px);
                        transform-origin: 0 0 0;
                        transition: 0.5s transform 0.3s;
                    }
                    #oval2 {
                        transform: scale(0) translate(-55px, -30px);
                        transform-origin: 0 0 0;
                        transition: 1.5s transform 0.3s;
                    }
                }
                #grp2 {
                    opacity: 1;
                    transition: 0.1s opacity 0.3s;
                }
                #grp3 {
                    opacity: 1;
                    transition: 0.1s opacity 0.3s;
                }
                #grp4 {
                    opacity: 1;
                    transition: 0.1s opacity 0.3s;
                }
                #grp5 {
                    opacity: 1;
                    transition: 0.1s opacity 0.3s;
                }
                #grp6 {
                    opacity: 1;
                    transition: 0.1s opacity 0.3s;
                }
                #grp7 {
                    opacity: 1;
                    transition: 0.1s opacity 0.3s;
                }
            }

            @keyframes animateCircle {
                40% {
                    transform: scale(10);
                    opacity: 1;
                    fill: #1f40e6;
                }
                55% {
                    transform: scale(11);
                    opacity: 1;
                    fill: #1f40e6;
                }
                65% {
                    transform: scale(12);
                    opacity: 1;
                    fill: #1f40e6;
                }
                75% {
                    transform: scale(13);
                    opacity: 1;
                    fill: transparent;
                    stroke: #1f40e6;
                    stroke-width: 0.5;
                }
                85% {
                    transform: scale(17);
                    opacity: 1;
                    fill: transparent;
                    stroke: #1f40e6;
                    stroke-width: 0.2;
                }
                95% {
                    transform: scale(18);
                    opacity: 1;
                    fill: transparent;
                    stroke: #1f40e6;
                    stroke-width: 0.1;
                }
                100% {
                    transform: scale(19);
                    opacity: 1;
                    fill: transparent;
                    stroke: #1f40e6;
                    stroke-width: 0;
                }
            }

            @keyframes animateHeart {
                0% {
                    transform: scale(0.2);
                }
                40% {
                    transform: scale(1.2);
                }
                100% {
                    transform: scale(1);
                }
            }

            @keyframes animateHeartOut {
                0% {
                    transform: scale(1.4);
                }
                100% {
                    transform: scale(1);
                }
            }
        }
        .comment {
            width: 50%;
            display: flex;
            justify-content: center;
            &__icon {
                margin-right: 6px;
            }
        }
    }
    .commentWrapper_first_el {
        width: 100%;
        display: flex;
        .post_comment_btn {
            margin: 22px 22px 0 0;
            font-size: 14px;
            color: $blue100;
            cursor: pointer;
            font-family: 'Theinhardt-Medium';
        }
        .commentSection {
            width: 80%;
            height: 60px;
            font-size: 1rem;
            line-height: 1.25;
            letter-spacing: 0.2px;
            margin: 0 0 1.75rem 1.75rem;
            border: none;
        }
    }
    .total_number_of_likes {
        padding: 1.25rem;
        display: flex;
        align-items: center;
        div {
            margin-right: 0.5rem;
        }
    }
}
