@import '../../styles/variables';
@import '../../styles/responsive';

.reminder-success-modal {
    padding: 24px 24px 48px;
    background-color: $white;
    display: flex;
    width: 680px;
    flex-direction: column;

    .header-actions {
        display: flex;
        justify-content: space-between;
        
        .back {
            width: 22px;
            height: 20px;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .cross {
            width: 20px;
            height: 20px;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .reminder-modal-content {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: 4px;
    }

    .add-calendar-container {
        display: flex;
        margin-top: 24px;
    }
}

@media (max-width: $breakpointMobileLandScape) {
    .reminder-success-warning-modal {
        .reminder-success-modal {
            width: 100%;
        }
    
        .MuiDialog-paper {
            border-radius: 5px !important;
            width: 100%;
            margin: 24px;
        }
    }
}
