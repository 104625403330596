@import '../../styles/variables';
@import '../../styles/functions';

.container404 {
    min-height: 100vh;
    a {
        padding: rem(9) rem(53);
        background-color: $black100;
        font-size: rem(14);
        color: $white;
        border-radius: rem(20);
        &:hover {
            background-color: $black100;
        }
    }
}

.text404 {
    font-family: 'TheinhardtPan';
    font-size: rem(23);
    font-weight: 500;
    letter-spacing: 0.29px;
    color: $slateLightColor;
}
