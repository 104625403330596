.client-expire{
    width: 100%;
    height: 100vh;
    background: #ffffff;
    
    
    .content_wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        height: 100vh;
       
        
        .err_img{

        }

        .err_title{
            width: 200px;
            font-family: PlayfairDisplay;
            font-weight: 700;
            font-size: 28px;
            line-height: 32px;
            margin-top: 30px;
        }

        .err_description{
            width: 350px;
            font-family: Theinhardt-Regular;
            font-weight: 400;
            font-size: 18px;
            margin-top: 0px;
        }

        .err_btn{
            width: 334px;
            margin-top: 20px;
        }
    }
    
    
}
