@import '../../styles/variables';
@import '../../styles/functions';

.searching {
    position: absolute;
    left: 75%;
    margin-top: -20px;
}

.editor {
    cursor: text;
    margin-bottom: 2em;
    background: #fefefe;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    overflow: hidden;
    padding: 0 1.25rem;

    .DraftEditor-root {
        display: flex;
        width: 100%;
        height: auto;
        .DraftEditor-editorContainer {
            height: 100%;
            width: 100%;
        }
        .public-DraftStyleDefault-ltr {
            word-break: break-word;
        }
    }

    .emojis_selector {
        & > div:first-child {
            & > button:first-child {
                font-size: 34px;
                margin: 0 20px;
                width: auto;
                height: auto;
            }

            & > div:nth-child(2) {
                li {
                    padding: 2px;
                    button {
                        font-size: 24px;
                        img {
                            width: 1em !important;
                            height: 1em !important;
                        }
                    }
                }

                & > div:nth-child(2) {
                    & > div:first-child {
                        & > div:first-child {
                            overflow-x: hidden !important;
                            overflow-y: scroll !important;
                        }

                        & > div:first-child::-webkit-scrollbar-button {
                            display: none;
                        }

                        & > div:first-child::-webkit-scrollbar {
                            width: 6px;
                            background-color: transparent;
                        }

                        & > div:first-child::-webkit-scrollbar-thumb {
                            background: $grey500;
                            border-radius: 20px;
                        }
                    }
                }
            }
        }
    }
}

.suggestions_container {
    position: absolute;
    z-index: 100;
    .suggestion{
        display: flex;
        margin:8px 0;
        pointer-events: visible;
        width:400px;
        .profile_picture{
            margin:0 10px;
            img {
                border-radius: 0;
                height: 33px;
                width: 33px;
                clip-path: url(#comment_bubble);
            }
        }
        .user-details{
            font-family: TheinhardtPan_medium;
            .mentioned_user-name{
                color:$slateLightColor;
                font-size: 14px;
                text-transform: uppercase;
                margin-bottom: 5px;
                word-break: break-word;
            }
            .mentioned_user-email{
                font-family: TheinhardtPan_regular;
                color:$grey500;
                font-size: 12px;
            }
        }
    }
}

.emojis_selector {
    & > div:first-child {
        & > button:first-child {
            border: 0px !important;
        }
    }
}

.editor :global(.public-DraftEditor-content) {
    min-height: 140px;
}

.mentioned_user {
    color: $blue100;
}

.commentSection {
    .public-DraftEditorPlaceholder-root {
        padding-top: 0;
    }
    .public-DraftEditor-content {
        padding-top: 0;
    }
}

.public-DraftEditorPlaceholder-root {
    padding-top: rem(20);
    // width: rem(200);
}

.public-DraftEditor-content {
    padding-top: rem(20);
}
