@import '../../styles/variables';
@import '../../styles/functions';
@import '../../styles/responsive';

@font-face {
    font-family: 'hatton_bold';
    src: url('../../assets/fonts/Hatton-Bold.woff2');
}

@font-face {
    font-family: 'TheinhardtPan_medium';
    src: url('../../assets/fonts/TheinhardtPan-Medium.otf');
    font-weight: 500;
}

.weekly_questions_outer {
    top: 50% !important;
    left: 50% !important;
    width: 52vw;
    height: auto;
    min-height: 70%;
    padding-bottom: rem(25);

    .wrapper_weekly_ques {
        transform: translate(-50%, -50%);
        width: 65vw;
        max-width: 887px;
        height: 100%;
        min-height: 60%;
        background-color: $white;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: 10px;
        outline: none;

        .inner_wrapper_weekly_ques {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            height: 100%;
            width: 100%;
            .done {
                position: absolute;
                bottom: rem(20);
                right: rem(30);
                cursor: pointer;
                width: rem(126);
                height: rem(36);
                border-radius: rem(18);
                background-color: $black100;
                display: flex;
                justify-content: center;
                align-items: center;
                span {
                    font-family: 'TheinhardtPan';
                    font-size: rem(14);
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: -0.09px;
                    text-align: center;
                    color: $white;
                }
            }

            .big_reveal_blob {
                width: 33%;
                height: 100%;
                overflow: hidden;
                margin-top: rem(33);
                margin-right: 5.0625rem;

                .image_wrapper {
                    display: flex;
                    flex-direction: row-reverse;
                    max-width: 100%;
                    max-height: 100%;
                    position: relative;
                    text-align: center;

                    .image_text {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: rem(220);

                        & > span:first-child {
                            font-family: 'hatton_bold';
                            font-size: rem(24);
                            font-weight: 500;
                            font-stretch: normal;
                            font-style: normal;
                            letter-spacing: normal;
                            text-align: center;
                            color: $white;
                        }

                        & > span:nth-child(2) {
                            font-family: 'TheinhardtPan_medium';
                            font-size: rem(14);
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.5;
                            letter-spacing: normal;
                            color: $white;
                        }
                    }

                    img {
                        background-size: contain;
                    }
                }
            }
        }

        .inner_wrapper_weekly_ques.centered {
            justify-content: center;
            align-items: center;
            .cross {
                position: absolute;
                right: 30px;
                top: 30px;
            }

            .thank_you_note {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .image_container {
                    margin-right: 36px;
                    img {
                        width: rem(250);
                    }
                }

                .thanks_note {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;

                    & > span:first-child {
                        font-family: 'hatton_bold';
                        font-size: 24px;
                        max-width: rem(240);
                        margin-bottom: rem(10);
                        font-stretch: normal;
                        color: $black100;
                    }
                    & > span:nth-child(2) {
                        max-width: rem(240);
                        font-size: 14px;
                        font-weight: 500;
                    }
                    .open_end_textbox {
                        margin-top: rem(17);
                        textarea {
                            padding: rem(10);
                            width: rem(265);
                            height: rem(93);
                            border-radius: 3px;
                            background-color: $lightGrey3;
                            border: none;
                            outline: none;
                        }
                        textarea::placeholder {
                            opacity: 0.5;
                            font-family: 'TheinhardtPan';
                            font-size: rem(14);
                            font-weight: 300;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: normal;
                        }
                    }
                }
            }
        }

        .weekly_questions_inner {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            padding: rem(33) 20px rem(33) 0;
            width: 67%;
            height: 100%;

            & > div:first-child {
                width: 100%;
                height: 100%;
                overflow: hidden;

                .stepper_container {
                    display: flex;
                    align-items: center;
                    margin-bottom: rem(24);
                    margin-top: rem(24);

                    .stepper {
                        width: 100%;
                        height: rem(6);
                        border-radius: rem(6.5);
                        margin-right: rem(2);
                        background-color: $grey500;
                    }
                }

                .question_container {
                    width: 100%;
                    height: 75%;
                    overflow: scroll;

                    &::-webkit-scrollbar-button {
                        display: none;
                    }

                    &::-webkit-scrollbar {
                        width: 10px;
                        background-color: transparent;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: $grey500;
                        border-radius: 20px;
                    }

                    .set_html_container {
                        h2 {
                            margin: rem(28) 0 rem(30) 0;
                            max-width: 370px;
                        }

                        h5 {
                            font-size: 12px;
                        }

                        div {
                            max-width: 450px;
                        }

                        ol_1 {
                            margin-block-end: rem(30);
                            padding-inline-start: 0;
                            column-count: 1;

                            li_1 {
                                margin-bottom: rem(30);
                                display: flex;
                                text-indent: 0%;

                                sequence {
                                    padding-top: 0;
                                    font-size: rem(36);
                                    margin-right: 18px;
                                    padding-top: 0px !important;
                                }

                                desc {
                                    max-width: 314px;
                                    font-size: 12px;
                                    margin-top: rem(6);
                                }
                            }
                        }
                    }

                    .real_question_text_container {
                        h2 {
                            margin: 0;
                        }

                        .meter_gauge {
                            width: auto;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: center;
                            cursor: pointer;
                            margin-bottom: 77px;

                            .needle_wrapper {
                                width: 100%;
                                height: auto;
                                display: flex;
                                justify-content: center;
                                margin-right: 28px;
                                margin-top: -15px;
                            }

                            .ratings_text_below {
                                width: 100%;
                                height: auto;
                                display: flex;
                                justify-content: center;
                                margin-top: 20px;
                                color: $black100;
                                font-size: rem(16);

                                span {
                                    color: $black100;
                                    font-size: rem(14);
                                }
                            }
                        }
                    }

                    .real_question_text_container_multiselect {
                        max-height: 60vh;

                        & > div:first-child {
                            font-size: 16px;
                            font-weight: 550 !important;
                            font-weight: 500;
                            color: $black200;
                            margin-top: 8px;
                            margin-bottom: 12px;
                        }

                        & > div:nth-child(2) {
                            font-size: 12px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.75;
                            margin: 0 0 8px 0;
                        }

                        & > div:nth-child(3) {
                            font-size: 12px;
                            color: $black100;
                        }

                        .multi_select_options_container {
                            max-height: 50vh;

                            .multiselect_option {
                                margin: 15px 0;
                                display: flex;
                                align-items: center;
                                opacity: 1;

                                label {
                                    font-size: 14px;
                                    font-weight: normal;
                                    color: $black100;
                                    margin-left: 16px;
                                }

                                input[type='checkbox'] {
                                    display: none;
                                }

                                input[type='checkbox'] + label {
                                    display: inline-block;
                                    padding-left: 40px;
                                    background-position: 0 6px;
                                    background-repeat: no-repeat;
                                    line-height: 32px;
                                    cursor: pointer;
                                    background-image: url('./assets/unselectedd.svg');
                                }

                                input[type='checkbox']:checked + label {
                                    background-image: url('./assets/combined-shape-copy.svg');
                                }
                                .open_end_response {
                                    margin: 0 30px 0 0;
                                    width: rem(150);
                                }
                            }

                            .multiselect_option.greyClass {
                                opacity: 0.3;
                            }
                        }
                    }

                    .single_select_options_container {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                        .objective_type_question_statement {
                            width: 100%;
                            font-size: rem(20);
                            color: $black100;
                            a {
                                text-decoration: underline;
                                color: #0000ee;
                                word-break: break-all;
                            }
                        }
                        .objective_type_question_options {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: flex-start;
                            margin-top: rem(35);
                            button {
                                cursor: pointer;
                                width: rem(250);
                                margin-bottom: rem(25);
                                background-color: $white;
                                border-radius: 20px;
                                border: 1px solid $black100;
                                height: 41px;
                            }
                            button.selected_single_select {
                                border-color: $themeOrange;
                                color: $themeOrange;
                            }
                        }
                    }
                }

                .call_to_action {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    margin-top: rem(25);
                    div {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        width: 126px;
                        height: 36px;
                        border-radius: 18px;
                        background-color: $lightGrey2;
                        font-size: 14px;
                        font-weight: 550;
                        color: $black100;
                        border: 1px solid $greyText;
                    }

                    div:hover {
                        background-color: $black100;
                        color: $white;
                    }

                    .disable_proceed {
                        opacity: 0.3;
                        pointer-events: none;
                    }
                }
            }

            .chapter_intro {
                width: 100%;
                overflow: scroll !important;
                &::-webkit-scrollbar-button {
                    display: none;
                }

                &::-webkit-scrollbar {
                    width: 10px;
                    background-color: transparent;
                }
                .dangerouslySetInnerHTML_chapter_intro {
                    & > div:first-child {
                        font-size: rem(15);
                        h3 {
                            font-size: rem(20);
                            color: $black100;
                        }
                        ul_1 {
                            li_3 {
                                font-size: rem(15);
                                word-break: break-word;
                            }
                        }
                    }
                }
                .call_to_action_chapter_intro {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    margin-top: rem(65);
                    button {
                        cursor: pointer;
                        width: rem(287);
                        height: rem(55);
                        border-radius: rem(34.5);
                        border: solid 1px $black100;
                        background-color: $black100;
                        color: $white;
                        font-size: rem(16);
                        font-weight: 500;
                        letter-spacing: -0.1px;
                    }
                }
            }
        }
    }
}

.weekly_questions_outer_2 {
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    width: 52vw;
    height: auto;
    min-height: 50vh;
    max-height: 75vh;
}

@media (max-width: $breakpointMobileLandScape) {
    .weekly_questions_outer {
        .wrapper_weekly_ques {
            width: 100vw;
            height: 100vh;
            max-width: 100vw;
            max-height: 100vh;
            .inner_wrapper_weekly_ques {
                width: 100%;
                height: 100%;
                max-height: 100%;
                flex-direction: column;
                justify-content: flex-start;
                .thank_you_note {
                    width: 100%;
                    flex-direction: column;
                    .image_container {
                        margin-right: 0 !important;
                        margin-bottom: rem(30) !important;
                        width: rem(250);
                    }
                }
                .big_reveal_blob {
                    margin: 0;
                    width: 100%;
                    height: 25vh;
                    background-color: $themeOrange;
                }
                .weekly_questions_inner {
                    width: 100%;
                    padding: rem(33) 20px;
                    height: 85%;
                    .chapter_intro {
                        overflow: scroll !important;
                        &::-webkit-scrollbar-button {
                            display: none;
                        }

                        &::-webkit-scrollbar {
                            width: 10px;
                            background-color: transparent;
                        }
                        .call_to_action_chapter_intro {
                            justify-content: center;
                        }
                    }
                    & > div:first-child {
                        .question_container {
                            .single_select_options_container {
                                .objective_type_question_options {
                                    align-items: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
