@import '../../styles/variables';
@import '../../styles/responsive';
@import '../../styles/functions';
@import '../../styles/default-classes';

@font-face {
    font-family: 'TheinhardtPan';
    src: url('../../assets/fonts/TheinhardtPan-Regular.otf');
}

@font-face {
    font-family: 'TheinhardtPan_medium';
    src: url('../../assets/fonts/TheinhardtPan-Medium.otf');
    font-weight: 500;
}

.user_activity_container {
    position: absolute;
    top: rem(42);
    right: rem(-5);
    z-index: 999;
    width: rem(420);
    height: rem(700);
    background-color: $white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11);
    .no_activity_data {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .back {
            position: absolute;
            top: rem(25);
            right: rem(25);
        }
        img {
            margin-top: rem(85);
            width: rem(200);
            height: rem(200);
        }
        & > span:nth-child(2) {
            margin-top: rem(40);
            font-family: 'TheinhardtPan_medium';
            font-size: rem(22);
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: $black100;
        }
        & > :nth-child(3) {
            margin-top: rem(8);
            font-family: 'TheinhardtPan';
            font-size: rem(18);
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #8e8e8e;
        }
    }
    .my_activities_wrapper {
        width: 98%;
        height: 100%;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 3px !important;
            background-color: #f4f4f4;
            cursor: pointer;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 3px;
            background-color: $grey900;
        }
        .my_activities_header {
            width: 100%;
            padding: rem(13) rem(25);
            border-bottom: 1px solid $grey100;
            font-family: 'TheinhardtPan_medium';
            font-size: rem(18);
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.11px;
            color: $black300;
        }
        .activity_rows {
            width: 100%;
            height: 100%;
            .activity_row_in_this_duration {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                .activity_creation_since {
                    padding: rem(20) rem(25) 0 rem(25);
                    font-family: 'TheinhardtPan';
                    font-size: rem(16);
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.71;
                    letter-spacing: 1.5px;
                    color: #787878;
                }
                .activity_row {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    border-bottom: 1px solid $grey100;
                    padding-top: rem(24);
                    padding-bottom: rem(24);
                    .activity_row_data {
                        width: rem(232);
                        padding-left: rem(25);
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        .activity_chapter_name {
                            font-family: 'TheinhardtPan';
                            font-size: rem(16);
                            font-weight: 600;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.71;
                            letter-spacing: normal;
                            color: #cccccc;
                        }
                        & > div:nth-child(2) {
                            font-family: 'TheinhardtPan_medium';
                            font-size: rem(18);
                            font-weight: 500;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.5;
                            letter-spacing: normal;
                            color: $black300;
                        }
                        & > div:nth-child(3) {
                            font-family: 'TheinhardtPan';
                            font-size: rem(14);
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.25;
                            letter-spacing: normal;
                            color: #818181;
                        }
                    }
                    .activity_card_img {
                        width: rem(120);
                        height: rem(100);
                        padding-right: rem(25);
                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: rem(4);
                        }
                    }
                }
                & > .activity_row:nth-child(2) {
                    padding-bottom: rem(24);
                    padding-top: 0;
                }
            }
        }
    }
}

.user_activity_container::after {
    content: '';
    right: rem(7);
    position: absolute;
    top: rem(-14);
    width: 0;
    height: 0;
    border-left: rem(10) solid transparent;
    border-right: rem(10) solid transparent;
    border-bottom: rem(14) solid $white;
}

@media (max-width: $breakpointMobileLandScape) {
    .user_activity_container {
        width: 100vw;
        height: 100vh;
        top: 0;
        right: 0;
        .my_activities_wrapper {
            .my_activities_header {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}
