.coachMarks_wrapper {
    width: 50vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem;
    .cross {
        position: absolute;
        right: 1.5rem;
        top: 1.5rem;
    }
    img {
        width: 220px;
        margin-bottom: 30px;
    }
    .steps {
        display: flex;
        flex-direction: column;
        margin-top: 1.5rem;
        .step {
            display: flex;
            margin-bottom: 1rem;
            .circle {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                width: 28px;
                height: 28px;
                background-color: #1f40e6;
            }
        }
    }
}
