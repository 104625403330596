@import '../../styles/variables';
@import '../../styles/responsive';

.prompt_detail_wrapper {
    display: flex;
    flex-direction: column;
    width: 700px;
    margin: auto;
    .cross {
        position: absolute;
        right: 2.5rem;
        cursor: pointer;
    }
    .title_and_image_wrapper {
        display: flex;
        width: 100%;
        margin-bottom: 10px;
        .image {
            width: 12.5rem;
            height: 12.5rem;
            margin-right: 2.5rem;
            img {
                width: 100%;
                height: 100%;
                background-size: contain;
            }
        }
        .title_and_subtitle {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
    .spacer{
        margin-top:10px;
    }
    .divider {
        width: 100%;
        margin: 15px 0;
        height: 1px;
        background-color: $borderGrey;
    }
    .feed_inner_html {
        width: 100%;
    }
    .ck-content{
        h1,h2,h3{
            margin: 1.4em 0;
        }
        h2{
            padding: 0;
        }
        .ck-content { padding: 0;}
    }
    
    .actions_wrapper_conntainer {
        display: flex;
        width: 100%;
        align-items: center;
        margin-top: 35px;
        justify-content: center;
        &.content_center{
            justify-content: center;
        }
        .action_wrapper {
            display: flex;
            flex-direction: column;
        }

        .content_center {
            justify-content: center;
        }
        
    }
    .info_popup {
        position: fixed;
        right: 10px;
        top: 10px;
        padding: 10px;
        background-color: white;
        border-radius: 5px;
        width: 350px;
        display: flex;
    }
}

@media (max-width: $breakpointMobileLandScape) {
    .prompt_detail_wrapper {
        width: 100vw;
        padding-top: 70px !important;
        .title_and_image_wrapper {
            .image {
                margin-right: 0;
                margin-bottom: 2.5rem;
            }
        }
        .actions_wrapper_conntainer {
            margin-left: 0;
            .action_wrapper {
                width: 100%;
                button {
                    width: 100% !important;
                }
            }
        }
        .feed_inner_html {
            width: 100%;
            a {
                word-break: break-word;
            }
            div,
            p,
            img {
                width: 100% !important;
                display: block !important;
                padding: 0 !important;
                margin-left: 0 !important;
                margin-right: 0 !important;
            }
        }
    }
    .info_popup {
        position: fixed;
        right: 10px;
        top: 10px;
        padding: 10px;
        background-color: white;
        border-radius: 5px;
        width: 350px;
        display: flex;
    }
}
