@import '../../styles/variables';
@import '../../styles/functions';
@import '../../styles/responsive';

@font-face {
    font-family: 'hatton_bold';
    src: url('../../assets/fonts/Hatton-Bold.woff2');
}

@font-face {
    font-family: 'TheinhardtPan_medium';
    src: url('../../assets/fonts/TheinhardtPan-Medium.otf');
    font-weight: 500;
}

.preferences_outer {
    top: 50% !important;
    left: 50% !important;
    width: rem(850);
    height: rem(540);
    min-height: rem(540);
    max-height: rem(540);

    .preferences_inner {
        transform: translate(-50%, -50%);
        width: rem(850);
        // height: 70%;
        min-height: rem(540);
        background-color: $white;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        border-radius: 10px;
        outline: none;
        .done_btn {
            position: absolute;
            bottom: rem(20);
            right: rem(30);
            cursor: pointer;
            width: rem(126);
            height: rem(36);
            border-radius: rem(18);
            background-color: $blue100;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
                font-family: 'TheinhardtPan';
                font-size: rem(14);
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.09px;
                text-align: center;
                color: $white;
            }
        }
        .done_btn.reverse {
            right: auto;
            left: rem(30);
        }
        .cross {
            position: absolute;
            top: rem(20);
            right: rem(30);
            cursor: pointer;
        }
        .reverse_cross {
            position: absolute;
            top: rem(20);
            left: rem(30);
            cursor: pointer;
        }
        .modal_heading {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: rem(20) rem(30);
            border-bottom: 1px solid rgba($color: $black100, $alpha: 0.2);
            .cretePostCross {
                width: 1.5rem;
                height: 1.5rem;
                border-radius: 50%;
                background-color: #0000ee;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 50%;
                }
            }
        }
        .modal_heading.reverse {
            img {
                margin-right: 0;
                margin-left: rem(12);
            }
        }
        .main_content {
            width: 100%;
            height: 100%;
        }

        .main_content.profileWrapper {
            background: none;
            background-repeat: no-repeat;
            display: flex;
            .profile_picture_holder_container {
                width: auto;
                margin-top: 32px;
                height: 187px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                position: relative;
                .image_div_container {
                    position: absolute;
                    bottom: 0;
                    right: rem(100);
                    display: flex;
                    justify-content: flex-end;
                    outline: none;
                    .image_div {
                        z-index: 2;
                        & > div:first-child {
                            height: rem(35);
                            width: rem(35);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: $blue100;
                            border-radius: 50%;
                        }
                    }
                }
                .image_div_container_adjusted {
                    position: absolute;
                    bottom: 0;
                    right: rem(100);
                    display: flex;
                    justify-content: flex-end;
                    outline: none;
                    .image_div {
                        z-index: 2;
                        & > div:first-child {
                            height: rem(35);
                            width: rem(35);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: $blue100;
                            border-radius: 50%;
                        }
                    }
                }
                .message_label {
                    font-family: 'TheinhardtPan';
                    font-size: rem(12);
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.83;
                    letter-spacing: normal;
                    color: $grey500;
                }
            }
            .profile_picture_holder_container.reverse {
                margin-right: 0;
                margin-left: rem(81);
            }
            .questions_container {
                display: flex;
                flex-direction: column;
                //justify-content: flex-start;
                //align-items: flex-start;
                margin-top: 32px;
                .question {
                    margin-bottom: rem(35);
                    .question_text {
                        font-family: TheinhardtPan;
                        font-size: rem(16);
                        font-weight: 600;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 2;
                        letter-spacing: normal;
                        color: $black100;
                    }
                    #standard-name_namee {
                        font-size: rem(16);
                        width: rem(274);
                    }
                    #demo-simple-select_timee {
                        font-size: rem(16);
                        width: rem(220);
                    }
                    .email_display {
                        font-size: rem(16);
                        width: rem(274);
                    }
                }
            }
        }
    }
}

.image_selector_container {
    top: 50% !important;
    left: 50% !important;

    .image_selector {
        width: 50vw;
        height: 60vh;
        background-color: $white;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        outline: none;

        .crop-selector-container {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding: rem(30);

            .drag_drop_image_container {
                display: flex;
                justify-content: center;
                align-items: center;

                .selection_area {
                    cursor: pointer;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    & > svg:first-child {
                        position: absolute;
                    }
                    & > svg:nth-child(3) {
                        position: absolute;
                        top: 42%;
                    }
                    & > span {
                        position: absolute;
                        top: 51%;
                        width: rem(160);
                        font-size: 12px;
                        text-align: center;
                    }
                    & > span > input {
                        opacity: 0;
                        overflow: hidden;
                        position: relative;
                        height: rem(260);
                        width: rem(260);
                        z-index: 2;
                        top: rem(-150);
                        right: rem(52);
                        cursor: pointer;
                    }
                }
            }

            .upload_button_container {
                width: 100%;
                display: flex;
                justify-content: flex-end;

                div {
                    &:hover {
                        background-color: $black100;
                    }
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: rem(40);
                    border-radius: rem(18);
                    width: rem(126);
                    font-size: 14px;
                    font-weight: 500;
                    color: $white;
                    background-color: $grey500;
                    cursor: pointer;
                }
            }

            .close_icon_selector {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                cursor: pointer;
                outline: none;
            }
        }

        .crop-container {
            height: 70%;
            width: 100%;

            & > div:first-child {
                position: relative !important;
                width: 100%;
                height: 100%;
            }
        }
        .mobile-crop-container {
            width: 100%;
            height: 100%;
            .mobile-img-container {
                width: 100%;
                height: 100%;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

@media (max-width: $breakpointMobileLandScape) {
    .preferences_outer {
        width: 100%;
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        .preferences_inner {
            width: 100%;
            height: 100%;
            .main_content.profileWrapper {
                flex-direction: column;
                .profile_picture_holder_container {
                    margin: 0;
                    margin-bottom: rem(20);
                }
                .questions_container {
                    padding: 0 rem(24);
                }
            }
        }
    }
    .image_selector_container {
        .image_selector {
            max-width: initial !important;
            width: 100vw !important;
            height: 100vh !important;
            .crop-selector-container {
                .upload_button_container {
                    justify-content: center;
                    margin-top: 10px;
                }
            }
        }
    }
    .profileWrapper {
        .tndSection {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            & > div:first-child {
                .profileHeading {
                    margin-top: 20%;
                }
                .profileText {
                    padding: 1.5rem 3rem;
                }
            }
            form {
                display: none;
            }
        }
        .profile_main_section {
            .profileTabs {
                border-radius: 0;
                box-shadow: none;
                & > div:first-child {
                    border-radius: 0;
                }
                .create_profile_image_wrapper {
                    align-items: flex-start !important;
                    margin-right: rem(50);
                    .mobile_profile_pic_des {
                        width: 100%;
                        font-size: rem(20);
                        font-weight: 550;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.45;
                        letter-spacing: normal;
                        color: $black100;
                    }
                    .profilePicture {
                        padding: 0;
                    }
                }
                .mobile_profile_form_wrapper {
                    margin-top: 40px;
                    .questionText {
                        font-size: rem(20);
                        font-weight: 550;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.45;
                        letter-spacing: normal;
                    }
                    .questionnaire {
                        .btn-group {
                            flex-direction: column;
                            justify-content: space-between;
                            .onlyTime {
                                font-size: 18px;
                                font-weight: 500;
                                font-stretch: normal;
                                font-style: normal;
                            }
                        }
                    }
                }
                .nextButton {
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        .profile_footer_section {
            display: none;
        }
        .footer-links {
            margin-bottom: 36px;
        }
    }
}
