// Colors
$siteTextColor: #666666;
$white: #ffffff;
$bgColor: #fafafe;

$blueLoginBg: #051b2c;

$blue100: #1f40e6;

$grey50: #fbfbfb;
$grey100: #cbcbcb;
$grey200: #eaeaea;
$grey300: #f0f0f3;
$grey400: #f2f2f2;
$grey500: #999999;
$grey600: #dddddd;
$grey700: #e9e9e9;
$grey800: #e8e8e8;
$grey900: #c7c7c7;
$grey1000: #252525;
$grey1100: #979797;
$grey1200: #d4d4dc;
$grey1300: #9a9a9a;
$grey1400: #b1b1b1;
$grey1500: #818181;
$grey1600: #767676;
$grey1700: #333333;
$green: #2A823F;

$black100: #000000;
$black200: #051c2c;
$black300: #272727;
$black400: #4d4d4d;
$black500: #010101;
$black600: #3f3f3f;
$greyText: #7f7f7f;
$greyMidText: #666;
$red100: #ff0000;
$notificationRed: #f02849;
$textOrange: #d74d1f;
$themeOrange: #d63f1c;
$labelOrange: #d7a382;
$darkgrey: #929292;
$orange: #e26a1f;
$lightGreyish: #acb4be;
$orangeBright: #ff642e;
$lightGrey2: #f4f4f4;
$lightGrey3: #f5f5f5;
$lightPurple: #ebebf2;
$pastelPurple: #f5f5ff;
$slateColor: #282828;
$slateLightColor: #3e3e3e;
$lightestPurple: #dadaf7;
$base-font-size: 1rem;

$commentBg: #e9ebfd;
$blueBtns: #2a37ef;
$indentComment: #cfd1e4;
$borderOrange: #ce4525;
$maroonRed: #bd150b;
$pastelGreen: #63cc09;
$slateGrey: #f0f0f0;
$StepperProgress: #25ca06;
$skeletonBlue: #dedeea;
$brightOrange: #ff9500;
$slateBlue: #c4d5ff;
$feedbackQuestionGreen: #e2f5ee;

// Inspire 1.5 color defs
$bgFilterGrey: #f5f5f8;
$borderGrey: #d8d8d8;
$borderGrey2: #e5e5e5;
$darkBlue100: #101357;
$blueLight: #2251FF;

$electricBlue900: #061F79;
$electricBlue200: #99C4FF;

$textColorDisabled: #B3B3B3;
$backgroundColorDisabled: #FAFAFB;
$indigoDye: #0E2B99;
