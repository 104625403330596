@import '../../styles/variables';
@import '../../styles/responsive';

.added-to-do{
    display: flex;
    font-size: 16px;
    font-family: TheinhardtPan_regular;
    color:$slateLightColor;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .to-do-icon{
        margin-right: 6px;
        margin-top: 2px;
        display: flex;
    }
}
.reminder{
    margin-bottom: 32px;
    color : $slateLightColor;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__title{
        font-size: 16px;
        font-family: TheinhardtPan_regular;
        color:$slateLightColor;
        margin-right: 10px;
    }
    &__pg-title{
        color: black;
        text-decoration: underline;
        cursor: pointer;
        font-family: Mckinsey-Sans-Medium;
        font-size: 16px;
    }
    &__button{
        background-color: $blue100;
        color: white;
        font-family: Theinhardt-Regular;
        padding: 10px 20px;
        border-radius: 20px;
        margin-left: 10px;
        cursor: pointer;
    }
    &__pg{
        margin-bottom: 10px !important;
    }
}

input[type='date']::selection {
    background-color: rgba(248, 90, 192, 0.56);
}

.reminder-modal-desktop {
    max-width: 680px;
    padding: 48px 20px !important;
    width: 100% !important;

    .modal-content {
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .reminder-dateTime {
        display: flex;
    }
}

.reminder-modal {
    .modal-content{
        height: 600px;
    }
    .reminder-dateTime {
        display: flex;
        .date{
            position: relative;
            font-size: 1px;
        }
        .display-date{
            z-index: 99;
            position: absolute;
            top: 18px;
            font-size: 16px;
            font-family: Theinhardt-Regular;
            left: 16px;
            letter-spacing: 1px;
            background-color: white;
        }
        .date-field{
            color:white !important;
            background-color: white;
        }
        
        .time {
            margin-right: 0 !important;
            .time-picker {
                position: absolute;
                top: 60px;
                width: 350px;
                left:0
            }
        }
        .date-field, .time {
            border: 1px solid #999999;
            margin-right: 1rem;
            width: 220px;
            position: relative;
            padding: 16px;
            border-radius: 6px;
            font-family: TheinhardtPan_regular;
            font-size: 16px;
            letter-spacing: 1px;
        }
        
        .calendar-icon, .time-icon {
            position: absolute;
            right: 20px;
            top: 16px;

        }
        
        .calendar-icon{
            z-index: 100;
            right: 34px;
            background:white;
            pointer-events: none;
        }
    }
}

@media (max-width: $breakpointMobileLandScape) {
    .reminder-modal{
        padding-top: 70px !important;
        min-width: 100vw;
        height: 100vh;
    }
    .reminder-dateTime{
        position: relative;
        .date{
            width:50%;
            margin-right: 2%;
            .date-field{
                width: 100%;
                padding-right: 8%;
            }
            .calendar-icon{
                right: 8%;
            }
        }
        .time{
            position: static !important;
            width: 50%;
            margin-right: 0;
            .time-picker{
                width: 92vw !important;
                //left: -110% !important;
            }
        }
    }
}

