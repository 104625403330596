.ImageContent {
    width: 100%;
    display: flex;
    justify-content: center;
    img {
        width: 100%;
    }
}

.TextDownloadContent {
    width: 100%;
    overflow-y: hidden;
    min-height: 15.625rem;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
    .svgWrapper {
        position: absolute;
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        z-index: 0;
        right: 0;
        &::-webkit-scrollbar {
            width: 0 !important;
            background-color: white;
            cursor: pointer;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background-color: white;
        }
        svg {
            max-width: 25rem;
        }
    }
    .quote {
        margin-bottom: 2rem;
    }
    & > div:nth-child(3) {
        position: relative;
        z-index: 1;
        word-break: break-word;
    }
}

.VideoContent {
    width: 100%;
    overflow-y: hidden;
    min-height: 15.625rem;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
}

/* @media (max-width: $breakpointMobileLandScape) {
    .TextContent {
        .svgWrapper {
            svg {
                max-width: 15rem;
            }
        }
    }
}*/
