@import 'variables';
@import 'responsive';
@import 'default-classes';
@import 'functions';
@import '../components/TagUser/index.scss';
@import '../containers/WeeklyQuestions/index.scss';
@import '../containers/MyProgress/index.scss';
@import '../containers/PreferencesModal/index.scss';
@import '../containers/UserActivity/index.scss';
@import '../containers/Unsubscribe/UnsubscribeComponent/index.scss';
@import '../containers/NotificationCenter/index.scss';

@font-face {
    font-family: 'hatton_bold';
    src: url('../assets/fonts/Hatton-Bold.woff2');
}

@font-face {
    font-family: 'Theinhardt-Medium';
    src: url('../assets/fonts/Theinhardt-Medium.otf');
}

@font-face {
    font-family: 'Theinhardt-Bold';
    src: url('../assets/fonts/Theinhardt-Bold.otf');
    font-weight: 600;
}

@font-face {
    font-family: 'Theinhardt-Regular';
    src: url('../assets/fonts/Theinhardt-Regular.otf');
}

@font-face {
    font-family: 'playfair_regular';
    src: url('../assets/fonts/PlayfairDisplay-Regular.otf');
}

@font-face {
    font-family: 'playfair_bold';
    src: url('../assets/fonts/PlayfairDisplay-Bold.otf');
}
@font-face {
    font-family: 'Mckinsey-Sans-Regular';
    src: url('../assets/fonts/McKinseySans-Regular.otf');
}
@font-face {
    font-family: 'Mckinsey-Sans-Medium';
    src: url("../assets/fonts/McKinseySans-Medium.otf");
}
@font-face {
    font-family: 'McKinsey-Sans-Light';
    src: url("../assets/fonts/McKinseySans-Light.otf");
}


@font-face {
    font-family: 'Curly';
    src: url('../assets/fonts/Curly.ttf');
}

@font-face {
    font-family: 'Typewritter';
    src: url('../assets/fonts/Typewritter.ttf');
}

button,a,div{
    -webkit-tap-highlight-color: transparent;
}

body,
html {
    padding: 0;
    height: 100%;
    width: 100%;
    font-size: 16px;
    line-height: 1.6;
}

body {
    @media screen and (max-width: $breakpointMobileLandScape) {
        font-size: 10px;
    }

    @media screen and (min-width: $screen-lg) {
        font-size: 18px;
    }

    @media screen and (min-width: $screen-xl) {
        font-size: 20px;
    }
}

body {
    #root {
        width: 100%;
        min-height: 100%;
        height: 100%;

        .contentWrapper {
            .header {
                color: $white;
                border-bottom: rem(1) solid $grey200;
                font-size: rem(13);
                ul {
                    float: right;
                    li {
                        margin: rem(0) rem(12);
                        float: left;
                        cursor: pointer;

                        &:first-child {
                            display: inline-block;
                            margin-left: rem(0);
                        }
                        &:last-child {
                            margin-right: rem(0);
                        }
                    }
                }
            }
        }
    }

    strong {
        font-weight: bold;
    }
    ol_1,
    ul_1 {
        display: block;
        list-style-type: decimal;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: rem(16);
        list-style-type: none;
        list-style-position: outside;
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }

    li_2 {
        display: list-item;
        text-align: -webkit-match-parent;
        padding: rem(2);
        display: list-item;
        line-height: 1.8;
    }

    li_2::before {
        content: '\2022';
        color: $black100;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
        font-size: 10px;
    }

    li_1,
    li_3 {
        word-break: break-word;
        display: list-item;
        text-align: -webkit-match-parent;
        padding: rem(2);
        display: list-item;
        line-height: 1.8;
    }

    li_3::before {
        content: '\2022';
        color: $black100;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
        font-size: 10px;
    }
    li_1 {
        text-indent: -1.875rem;
        padding-right: rem(10);
    }
    li_3 {
        line-height: 2.2;
        padding-right: rem(23);
    }
    sequence {
        padding: rem(10);
        color: $themeOrange;
        font-size: rem(14);
        font-family: 'hatton_bold';
    }
    p {
        line-height: 1.5;
    }
}

.clearFix {
    &:after {
        content: '.';
        display: block;
        height: rem(0);
        clear: both;
        visibility: hidden;
    }
}
.d-flex {
    display: flex;
}

// @media #{$mobileLandscape} {
//     html {
//         transform: rotate(-90deg);
//         transform-origin: left top;
//         width: 100vh;
//         overflow-x: hidden;
//         position: absolute;
//         top: 100%;
//         left: rem(0);
//     }
// }
@media only screen and (max-width: $breakpointTabLandscape) {
    #root {
        .contentWrapper {
            width: 100%;
            padding: rem(30) rem(32);
            padding: rem(0);
            height: 100%;
        }
    }
}

.wip {
    text-align: center;
    font-size: 25px;
}
.outline-none {
    outline: none;
}

.is_a_user {
    color: $blueBtns !important;
    cursor: pointer;
}

.full-width {
    width: 100% !important;
}

.feeds_goal_details_container {
    top: 50% !important;
    left: 50% !important;
    width: 52vw;
    height: auto;
    min-height: 256px;
    max-height: 75vh;

    .feeds_goal_details {
        overflow: scroll;
        transform: translate(-50%, -50%);
        width: 52vw;
        max-width: 708px;
        height: auto;
        max-height: 75vh;
        background-color: $white;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        border-radius: 10px;
        outline: none;
        padding: 30px;
        &::-webkit-scrollbar {
            width: 8px !important;
            background-color: #f4f4f4;
            cursor: pointer;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background-color: $grey900;
        }

        .close_details {
            position: absolute;
            right: 20px;
            top: 20px;
        }

        .goalDetails {
            .goalNonDividedList {
                ul {
                    column-count: 1 !important;
                }
            }
        }

        .notesWrapper {
            margin-top: 20px;
            .notesInput .heading {
                font-size: 16px;
                font-weight: 500;
                line-height: 1.31;
                color: #272727;
                margin-bottom: 0.9375rem;
            }
        }

        .vcrbText {
            font-size: 16px;
            font-family: 'hatton_bold';
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: $black100;
            display: flex;
            align-items: center;
            margin-left: 8px;
        }

        .lowerText {
            font-size: 12px;
        }

        .goalList {
            ul {
                column-count: 2;
                padding-left: 0.85rem;
                padding-right: 0.85rem;

                li {
                    line-height: 2.5;
                    padding-right: 0.625rem;
                }
            }
        }
    }
}

@media (max-width: $breakpointMobileLandScape) {
    .feeds_goal_details {
        width: 100vw !important;
        height: 100vh !important;
        max-width: 100vw !important;
        max-height: 100vh !important;
        .goalList {
            ul {
                column-count: 1 !important;
            }
        }
    }
}

.black_bg {
    background-color: black;
}

.no_border_radius {
    border-radius: 0 !important;
}

.pointer_events_none {
    pointer-events: none;
}

.badge_drawer {
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    justify-content: flex-start;
    padding: rem(10) rem(10) rem(10) 0;
    img {
        margin-left: rem(31);
        width: rem(40);
    }
    span {
        width: rem(150);
        margin-left: rem(12);
        font-size: rem(16);
    }

    .streakBadgeSheetWrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: flex-start;
        .streakBadgeSheet {
            display: flex;
            flex-direction: column;
            margin: 5px;
            .streakRow {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                .badgeImageCount {
                    margin-left: 0 !important;
                }
                .streakRowChild {
                    margin-left: rem(31);

                    position: relative;
                    width: auto;
                    height: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}
.streakMobileCount {
    position: absolute;
    font-size: 6.5px;
    color: $black100;
}
.streakMobileContainer {
    width: 20%;
    height: 80px;
}
.flex_end {
    display: flex;
    justify-content: flex-end !important;
}
.reverse_direction {
    direction: rtl !important;
}

/* Slider */
.slick-slider {
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus {
    outline: none;
}
.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after {
    display: table;

    content: '';
}
.slick-track:after {
    clear: both;
}
.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
    > div{
        height: 100%;
    }
}
[dir='rtl'] .slick-slide {
    float: right;
}
.slick-slide img {
    display: block;
}
.slick-slide.slick-loading img {
    display: none;
}
.slick-slide.dragging img {
    pointer-events: none;
}
.slick-initialized .slick-slide {
    display: block;
}
.slick-loading .slick-slide {
    visibility: hidden;
}
.slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

// @charset 'UTF-8';
/* Slider */
// .slick-loading .slick-list {
//     background: #fff url('./ajax-loader.gif') center center no-repeat;
// }

/* Icons */
// @font-face {
//     font-family: "slick";
//     font-weight: normal;
//     font-style: normal;

//     src: url("./fonts/slick.eot");
//     src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"),
//         url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
// }
/* Arrows */
.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: 0.75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
    left: -25px;
}
[dir='rtl'] .slick-prev {
    right: -25px;
    left: auto;
}
.slick-prev:before {
    content: '←';
}
[dir='rtl'] .slick-prev:before {
    content: '→';
}

.slick-next {
    right: -25px;
}
[dir='rtl'] .slick-next {
    right: auto;
    left: -25px;
}
.slick-next:before {
    content: '→';
}
[dir='rtl'] .slick-next:before {
    content: '←';
}

/* Dots */
.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li {
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button {
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    opacity: 1;
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: 0.25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: black;
}

.magic-dots.slick-dots ul {
    padding: 0;
    display: flex;
    transition: all 0.2s;
    position: relative;
    margin: 0;
}
.magic-dots.slick-dots li.slick-active button:before {
    color: #00558b;
}
.magic-dots.slick-dots li button:before {
    transition: font-size 0.35s;
    font-size: 12px;
    content: '\2022';
}
.magic-dots.slick-dots li.small button:before {
    font-size: 8px;
    line-height: 20px;
}

.pointer {
    cursor: pointer;
}
a{
    color:#1890ff;
}


