@import '../../styles/variables';
@import '../../styles/responsive';
@import '../../styles/functions';
@import '../../styles/default-classes';

@font-face {
    font-family: 'TheinhardtPan';
    src: url('../../assets/fonts/TheinhardtPan-Regular.otf');
}

@font-face {
    font-family: 'TheinhardtPan_medium';
    src: url('../../assets/fonts/TheinhardtPan-Medium.otf');
    font-weight: 500;
}

.notificationsList_container {
    position: absolute;
    top: rem(42);
    right: rem(-8);
    z-index: 999;
    width: rem(350);
    border-radius: rem(8);
    height: rem(650);
    background-color: $white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11);
    .no_notifications_data {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .back {
            position: absolute;
            top: rem(25);
            right: rem(25);
        }
        img {
            margin-top: rem(85);
            width: rem(200);
            height: rem(200);
        }
        & > span:nth-child(2) {
            margin-top: rem(40);
            font-family: 'TheinhardtPan_medium';
            font-size: rem(22);
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: $black100;
        }
        & > :nth-child(3) {
            margin-top: rem(8);
            font-family: 'TheinhardtPan';
            font-size: rem(18);
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #8e8e8e;
        }
    }
    .notificationsList_wrapper {
        width: 100%;
        height: 100%;
        overflow: hidden;
        .notificationsList_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: rem(13) rem(25);
            font-family: 'TheinhardtPan_medium';
            font-size: rem(18);
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.11px;
            color: $black300;
            .mark_all_read {
                font-size: rem(12);
                font-family: 'TheinhardtPan';
                font-weight: 300;
                color: $blueBtns;
                text-decoration: underline;
                cursor: pointer;
            }
        }
        .loaderWrapper {
            width: 100%;
            display: flex;
            justify-content: center;
            svg {
                height: 20px;
                width: 20px;
                -webkit-animation: spin 0.7s linear infinite;
                -moz-animation: spin 0.7s linear infinite;
                animation: spin 0.7s linear infinite;
            }
            @-moz-keyframes spin {
                100% {
                    -moz-transform: rotate(360deg);
                }
            }
            @-webkit-keyframes spin {
                100% {
                    -webkit-transform: rotate(360deg);
                }
            }
            @keyframes spin {
                100% {
                    -webkit-transform: rotate(360deg);
                    transform: rotate(360deg);
                }
            }
        }
        .notification_rows {
            width: 97%;
            height: 88%;
            padding: 0 rem(25);
            overflow-y: auto;
            &::-webkit-scrollbar {
                width: 3px !important;
                background-color: #f4f4f4;
                cursor: pointer;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 3px;
                background-color: $grey900;
            }
            & > .notification_row:first-child {
                padding-top: rem(24);
            }
            .notification_row {
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding-top: 0;
                padding-bottom: rem(24);
                .notification_row_data {
                    width: rem(232);
                    margin-left: rem(20);
                    padding: 0 0 rem(20) 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    border-bottom: 1px solid $grey100;
                    .notification_name {
                        font-family: 'TheinhardtPan';
                        font-size: rem(14);
                        font-weight: 600;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.71;
                        letter-spacing: normal;
                        color: $black100;
                    }
                    .notification_created_at {
                        margin-top: rem(5);
                        font-family: 'TheinhardtPan';
                        font-size: rem(14);
                        font-weight: 300;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.71;
                        letter-spacing: normal;
                        color: $grey1400;
                    }
                }
                .notification_card_img {
                    width: auto;
                    height: auto;
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: rem(4);
                    }
                    .profile_image_adjusted {
                        margin-right: rem(10);
                    }
                }
            }
            .notification_row.faded {
                opacity: 0.5;
            }
        }
    }
}

.notificationsList_container::after {
    content: '';
    right: rem(7);
    position: absolute;
    top: rem(-14);
    width: 0;
    height: 0;
    border-left: rem(10) solid transparent;
    border-right: rem(10) solid transparent;
    border-bottom: rem(14) solid $white;
}

@media (max-width: $breakpointMobileLandScape) {
    .notificationsList_container {
        width: 100vw;
        height: 100vh;
        top: 0;
        right: 0;
        .notificationsList_wrapper {
            .notificationsList_header {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}
