@import '../../styles/responsive';

.single-notification {
    display: flex;
    &__back {
        display: flex;
        width: 10%;
        padding: 40px 0 0 40px;
        font-family: playfair_bold;
        font-size: 18px;
        justify-content: space-between;
        .arrow-icon {
            margin-right: 10px;
            cursor: pointer;
        }
    }
    &__content {
        width: 36%;
        margin-top: 14vh;
        margin-left: 5%;
    }
}

@media (max-width: $breakpointMobileLandScape) {
    .single-notification {
        &__content {
            width: 100%;
            left: -10%;
            position: relative;
        }
    }
}

@media (min-width: $breakpointMobileLandScape) and (max-width: $breakpointDesktop) {
    .single-notification {
        &__content {
            width: 60%;
        }
    }
}
