@import '../../styles/responsive';

.protag_animation_warning_modal {
    z-index: 1301 !important;
}

.protag_animation_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;

    background-image: url('../../assets/badgemodal.svg');
    background-position: center;
    background-size: cover;
    .cross {
        position: absolute;
        right: 1.5rem;
        top: 1.5rem;
    }
    .animation_wrapper {
        width: 50%;
    }
    .percenntage {
        position: relative;
        display: flex;
        top: -40px;
    }
    .shareTHought {
        display: flex;
        margin-top: 1rem;
        cursor: pointer;
        align-items: baseline;
    }
    .p_wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 20px 0 20px;
    }
    .protag-button {
        display: flex;
        justify-content: center;
    }
}


@media (max-width: $breakpointMobileLandScape) {
    .protag_animation_wrapper {
        background-image: url('../../assets/badgemodel_mobile.svg');
        background-position: top;
        height: 100%;

        .animation_wrapper {
            max-width: 298px;
            width: auto;
        }

        .protag-button {
            width: 100%;

            button {
                max-width: 100%;
            }
        }
    }
}
